import { AppConfig } from "../../config/AppConfig";
import GameModel, { EGameStates } from "../../model/GameModel";
import DebugService from "../../services/DebugService";
import GameScreen from "../screens/GameScreen";
import gsap from 'gsap';

const { default: ResourceList } = require("../../services/ResourceList");
const { default: SpriteCommon } = require("./common/SpriteCommon");

class Disk extends SpriteCommon {
    /**
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameScreen, gameModel) {
        super(ResourceList.DISK);
        const { gameWidth, gameHeight } = AppConfig.settings;
        this.gameScreen = gameScreen;
        this.gameModel = gameModel;
        this.soundManager = gameScreen.soundManager;
        this.anchor.set(0.5); // Set anchor to the center
        this.scale.set(1);

        this.interactive = true;
        this.buttonMode = true; 
        this.eventMode = "dynamic";

        // Center coordinates for rotation
        this.centerX = 0;
        this.centerY = 0;
        this.radius = 120;
        this.previousAngle = 0;
        this._isDragging = false;
        this.fingerPoint = { x:gameWidth, y:gameHeight};
        this.alpha = 1;
        this.rSpeed = 0;
        this.rA = -0.001;
        this.lastTime = 0;

        this.lastSpRotation = 0;
        

        this.drawMarker(); // Optional: Visual marker

        // Listen for window resize events to re-position the disc
        this.onResize = () => {
            const { gameWidth, gameHeight, vPosDisk} = AppConfig.settings;
            this.x = gameWidth / 2;
            this.y = gameHeight * vPosDisk;
        };

        this.onSongLoaded = () => {
            gsap.to(this, { alpha: 1, duration: 0.9});
  
        }

        this.onGameRestarted = () => {
            this.rSpeed = 0;
            this.previousAngle = 0;
            this.angleMoveSum = 0;
            
        }

        this.onResize();
        AppConfig.sizeUpdated.add(this.onResize);
        this.soundManager.onSongLoadedSignal.add(this.onSongLoaded);
        this.gameModel.gameRestarted.add(this.onGameRestarted);

        this.interactive = true;
        this.on('pointerdown', this.onDragStart.bind(this));

        window.addEventListener('pointerup', this.onDragEnd.bind(this));
        window.addEventListener('pointermove', this.onDragMove.bind(this));
        this.angleMoveSum = 0;
        this.visible = true;
    }

    get isDragging() {return this._isDragging}
    set isDragging(value) {
        this._isDragging = value;
        // this.gameModel.isRotating = value;
        // this.gameScreen.fireworks.allowNewParticles = value;
    }

    // Start dragging
    onDragStart(event) {
        this.data = event.data;
        const startPosition = this.data.getLocalPosition(this.parent);
        this.fingerPoint = { x: event.x, y: event.y };


        // Calculate the initial angle when drag starts
        const dx = startPosition.x - this.x;
        const dy = startPosition.y - this.y;
        const radius = Math.sqrt(dx * dx + dy * dy);
        // console.log(radius, this.height / 2);
        if (radius < this.radius && this.gameModel.gameState === EGameStates.playing) { //is pressed on disk
            this.isDragging = true;
            if (this.gameModel.gameState === EGameStates.playing) {
                this.gameModel.isAutoPlay = true;
            }
        }
        this.previousAngle = Math.atan2(dy, dx);
    }

    // Stop dragging
    onDragEnd() {
        this.isDragging = false;
        this.data = null; // Clear the event data
        if (this.gameModel.gameState === EGameStates.playing) {
            this.gameModel.isAutoPlay = false;
            
        }
    }

    // Handle drag and calculate rotation
    onDragMove(event) {
        if (!this.isDragging) return;
        if (this.gameModel.gameState !== EGameStates.playing) return

        // Get the current position of the pointer
        const currentPointerPosition = this.data.getLocalPosition(this.parent);
        this.fingerPoint = { x: event.pageX, y: event.pageY };
        const dx = currentPointerPosition.x - this.x;
        const dy = currentPointerPosition.y - this.y;

        // Calculate the current angle relative to the center
        const currentAngle = Math.atan2(dy, dx);

        // Calculate the change in angle (delta)
        let deltaAngle = currentAngle - this.previousAngle;

        if (deltaAngle > Math.PI) {
            deltaAngle -= 2 * Math.PI;
        } else if (deltaAngle < -Math.PI) {
            deltaAngle += 2 * Math.PI;
        }

        if (isNaN(deltaAngle)) deltaAngle = 0;

        // Accumulate the rotation
        this.rotation += deltaAngle;
        this.angleMoveSum += deltaAngle;
        // this.gameModel.gameProgress = this.angleMoveSum;
        this.gameModel.trackedAngle = this.angleMoveSum;

        this.gameModel.avanceAngle += deltaAngle;
        this.rSpeed = deltaAngle;
        // Update the previous angle for the next move
        this.previousAngle = currentAngle;
    }


    // Draw a marker for visual reference
    drawMarker() {
        const marker = new PIXI.Graphics();
        marker.beginFill(0xffffff);
        marker.drawCircle(0, 0, 10);
        marker.endFill();
        marker.y = 200;
        marker.alpha = 0.5;
        // this.addChild(marker);
    }


    update(delta) {
        
        if (this.rSpeed >= 0.05) {
            this.gameModel.isRotating = true;
        } else {
            this.gameModel.isRotating = false;
        }
        if (!this.isDragging && this.gameModel.isRotating) {
            const deltaAngle = this.rSpeed;
            this.rotation += deltaAngle;
            // this.rSpeed += this.rA;
            this.angleMoveSum += deltaAngle;
            // this.gameModel.gameProgress = this.angleMoveSum;
            this.gameModel.trackedAngle = this.angleMoveSum;
            this.gameModel.avanceAngle += deltaAngle;
            this.rSpeed *= 0.99;
        }
        
        const rotDiff = this.rotation - this.lastRotation;
        if (rotDiff == 0) {
            this.gameModel.isRotating = false;
            this.rSpeed = 0;
        }
        this.lastRotation = this.rotation;
        // DebugService.logFPS("rotDiff:" + rotDiff + "; isRot " +  this.gameModel.isRotating + "; " + Math.random());
        // DebugService.logFPS( Math.random());
        // DebugService.log(delta);
    }
}

export default Disk;
