class GameTimer {
    constructor() {
        this.totalPlayTime = 0;    
        this.lastStartTime = null; 
        this.isPaused = true;      
    }

    start() {
        if (this.isPaused) {
            this.isPaused = false;
            this.lastStartTime = performance.now(); 
        }
    }

    reset() {
        this.totalPlayTime = 0;    
        this.lastStartTime = null; 
        this.isPaused = true;   
    }

    pause() {
        if (!this.isPaused) {
            this.isPaused = true;
            const timeSpent = performance.now() - this.lastStartTime; 
            this.totalPlayTime += timeSpent; 
        }
    }

    getTimeSpent() {
        return (this.totalPlayTime / 1000).toFixed(2); // Returns time in seconds
    }
}

export default GameTimer;

/*

// Example usage
const gameTimer = new GameTimer();

// When the game starts
gameTimer.start();

// When the game is paused
gameTimer.pause();

// When the game resumes again
gameTimer.start();

// When the game ends or you want to check play time
gameTimer.pause();
console.log(`Total time spent in the game: ${gameTimer.getTimeSpent()} seconds`);
*/