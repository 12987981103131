import * as PIXI from 'pixi.js';
import gsap from 'gsap';
import { Text } from 'pixi.js';
import { AppConfig } from '../../config/AppConfig';
import GameModel, { EGameStates } from '../../model/GameModel';
import GameScreen from '../screens/GameScreen';
import SpriteCommon from './common/SpriteCommon';
import ResourceList from '../../services/ResourceList';
import DebugService from '../../services/DebugService';

class PraiseWords extends PIXI.Container {
    /**
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameModel, gameScreen) {
        super();
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;

        this.avanceProgress = 0;
        this.gameProgress = 0;
        this.progress = 0;
        this.praiseTexts = [
            "¡HAZLO GIRAR!",
            "¡GÍRALO\nNUEVAMENTE!",
            "¡SIGUE GIRANDO!",
            "¡BIEN HECHO!"
        ]

        this.praise = new Text ("¡HAZLO GIRAR!", {
            fontFamily: 'MainMusicPlayer',
            fontWeight: 'bold',
            fontSize: 40,
            lineHeight: 48,
            fill: '#F6D040',
            // fillGradientStops: [0.5, 1],
            // stroke: '#4e0085',
            // strokeThickness: 3,
            align: 'center',
            dropShadow: true,
            dropShadowColor: '#4F2653',
            dropShadowDistance: 4,
            dropShadowBlur: 0,
            dropShadowAngle: 2 * Math.PI / 6,
        });
        this.caption = new Text ("Gira el disco hasta que completes \nla frecuencia musical", {
            fontFamily: 'SecondMusicPlayer',
            fontSize: 16,
            lineHeight: 23,
            fill: '#FFFFFF',
            align: 'center'
        });

        this.arrow = new SpriteCommon(ResourceList.ARROW);

        this.praise.anchor.set(0.5, 0);
        this.caption.anchor.set(0.5, 0);
        this.arrow.anchor.set(0.5, 0.5);

        this.addChild(this.praise);
        this.addChild(this.caption);
        this.addChild(this.arrow);
        this.visible = false;




        this.onResize = () => {
            const { gameWidth, gameHeight, vPosArrow, vPosPraise, vPosCaptionSpace } = AppConfig.settings;
            const w = gameWidth;
            const h = gameHeight;

            this.praise.y = h * vPosPraise;
            this.praise.x = w / 2;

            this.caption.x = w / 2;
            this.caption.y = this.praise.y + this.praise.height + h * vPosCaptionSpace;

            this.arrow.y = h * vPosArrow;
            this.arrow.x = w / 2;

        }

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                this.visible = true;
                this.alpha = 0;
                // ease: "power2.in",
                gsap.to(this, { alpha: 1, duration: 0.5, ease: "power2.in"});
            } else {
                
                this.alpha = 0;
                // ease: "power2.in",
                gsap.to(this, { alpha: 0, duration: 0.5, ease: "power2.in", onComplete: () => {
                    this.visible = false;
                } });
            }
            if (this.gameModel.gameState === EGameStates.stop){
                
            }
        };

        this.onAvanceSongTimeUpdated = (value) => {
            return
            
            this.avanceProgress = value;

            if (this.avanceProgress >= 0 && this.avanceProgress < 0.05) {
                this.arrow.visible = true;
                this.caption.visible = true;
                this.praise.text = this.praiseTexts[0];
            } else if (this.avanceProgress >= 0.05 && this.avanceProgress < 0.5) {
                this.arrow.visible = true;
                this.caption.visible = false;
                this.praise.text = this.praiseTexts[1];
            } else if (this.avanceProgress >= 0.5 && this.avanceProgress < 0.8) {
                this.arrow.visible = true;
                this.caption.visible = false;
                this.praise.text = this.praiseTexts[2];
            } else if (this.avanceProgress >= 0.8) {
                this.arrow.visible = false;
                this.caption.visible = false;
                this.praise.text = this.praiseTexts[3];
            }
            
        };

        this.onSongPositionUpdated = (value) => {
            return

        };
        this.gameProgressUpdated = (value) => {
            this.progress = value;
            

            DebugService.logFPS("gameProgress:" + value );


            this.gameProgress = value;

            if (this.gameProgress >= 0 && this.gameProgress < 0.05) {
                this.arrow.visible = true;
                this.caption.visible = true;
                this.praise.text = this.praiseTexts[0];
            } else if (this.gameProgress >= 0.05 && this.gameProgress < 0.5) {
                this.arrow.visible = true;
                this.caption.visible = false;
                this.praise.text = this.praiseTexts[1];
            } else if (this.gameProgress >= 0.5 && this.gameProgress < 0.7) {
                this.arrow.visible = true;
                this.caption.visible = false;
                this.praise.text = this.praiseTexts[2];
            } else if (this.gameProgress >= 0.7) {
                this.arrow.visible = false;
                this.caption.visible = false;
                this.praise.text = this.praiseTexts[3];
            }
        };

        this.onGameProgressUpdated = (value) => {
            this.progress = value;
        };

        this.onGameRestarted = (value) => {

            this.progress = 0;
        };

        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);
        // this.gameModel.avanceSongTimeUpdated.add(this.onAvanceSongTimeUpdated);
        this.gameModel.songPositionUpdated.add(this.onSongPositionUpdated);
        this.gameModel.gameProgressUpdated.add(this.onGameProgressUpdated);
        this.gameModel.gameRestarted.add(this.onGameRestarted);
        this.gameModel.gameProgressUpdated.add(this.gameProgressUpdated);

        AppConfig.sizeUpdated.add(this.onResize.bind(this));
        
        this.onResize();

    }

}

export default PraiseWords