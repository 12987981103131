
import { MiniSignal } from "mini-signals";

class AppConfig {

    static gameMode = window.gameMode;
    static lang = window.lang.toUpperCase();
    static sizeUpdated = new MiniSignal();
    static cameraUpdated = new MiniSignal();
    static isDebug = window.isDebug;
    static hidelogs = window.hidelogs;
    static totalrotates = parseFloat(window.totalrotates);
    static totalrotatesForWin = parseFloat(window.rotatesforwin);
    static playafterloaded = parseFloat(window.playafterloaded);
    static showFullAvanceProgress = window.showfullback;
    static hideBack = window.hideback;
    static maxLoaded = parseFloat(window.maxloaded);
    static pauseAfterStopMS = parseFloat(window.pauseafterstopms);
    static fastWaves = window.fastwaves;
    static startAfterAvanceSong = parseFloat(playafterloaded);


    /** 
     * @readonly
     */
    static base = {
        WIDTH: 375,
        HEIGHT: 730
    }


    /** 
     * @readonly
     */
    static settings = {
        gameSkin:1,
        gameWidth: AppConfig.base.WIDTH,
        gameHeight: AppConfig.base.HEIGHT,
        gameMinWidth: AppConfig.base.WIDTH,
        xResponsiveFactor: 1,
        yResponsiveFactor: 1,
        centerX: AppConfig.baseWidth / 2,
        vPosDisk:0.47,
        vPosWave:0.035,
        vPosArrow:0.75,
        vPosPraise:0.8,
        vPosCaptionSpace:0.005
    }


    static updateSize (w, h) {
        AppConfig.settings.gameWidth = w;
        AppConfig.settings.gameHeight = h;
        AppConfig.settings.xResponsiveFactor = w / AppConfig.base.WIDTH;
        AppConfig.settings.yResponsiveFactor = h / AppConfig.base.HEIGHT;
        AppConfig.sizeUpdated.dispatch(w, h);    
    }

    static updateParamsFromSound (songDuration) {
        AppConfig.maxLoaded = AppConfig.pauseAfterStopMS / (songDuration * 1000);
    }



    /** 
     * @readonly
     */
    static gameSettings = {
    };

}

export { AppConfig };