import GameModel from "../../model/GameModel";

class ReglesDOM {
    /**
     * 
     * @param {GameModel} gameModel 
     */
    constructor(gameModel) {
        // super(ResourceList.RULES);
        this.gameModel = gameModel;
        this._visible = undefined;
        this.domLink = document.getElementById('tutorial');
        this.closeBtnDOM = document.querySelector('.close-btn');

        if (this.domLink && this.closeBtnDOM) {
            this.closeBtnDOM.cursor = "pointer";
            // this.visible = this.gameModel.communictionService.shouldShowTutorial;

            this.closeBtnDOM.addEventListener('pointerup', () => {
                // this.gameModel.sendMessage(EMessages.SND_TUTORIAL_END);
                this.gameModel.showRules = false;
                this.visible = false;
            });
    
/*             this.gameModel.communictionService.tutorialStatusUpdated.add((shouldShow) => {
                this.visible = shouldShow;
            }); */

            this.gameModel.rulesStatusUpdated.add((shouldShow) => {
                this.visible = shouldShow;
            })
        }
    }

    /**
     * @type {boolean}
     */
    get visible(){ return this._visible}

    set visible(value) {
        if (this._visible === value) return
        this._visible === value;
        this.domLink.style.display = value ? "flex" : "none";
    }

}

export default ReglesDOM