class DebugService {}

/**
 * 
 * @param {string | object} message 
 * @param {("event" | "gameplay" | "")} str 
 */
DebugService.log = (message, type = "") => {
    DebugService.logIncr(message, type);
    return
    const reportMessages = document.querySelector('#logMessages');
    let reportStr = "";
    if (typeof message === 'object') {
        reportStr = JSON.stringify(message);
        reportMessages.innerHTML = reportStr.replaceAll( ",", ",\n");
    } else {

        // reportMessages.innerHTML = reportMessages.innerHTML + "\n" + message;
        reportMessages.innerHTML = "" + message;
    }

}

/**
 * 
 * @param {string | object} message 
 * @param {("event" | "gameplay" | "")} str 
 */
DebugService.logIncr = (message, type = "") => {
    const reportMessages = document.querySelector('#logMessages');
    let reportStr = "";
    if (typeof message === 'object') {
        reportStr = JSON.stringify(message);
        reportMessages.innerHTML = reportMessages.innerHTML + "\n<br />" +  reportStr.replaceAll( ",", ",\n");
    } else {

        reportMessages.innerHTML = reportMessages.innerHTML + "\n<br />" + message;
        // reportMessages.innerHTML = "" + message;
    }

}

/**
 * 
 * @param {string} value 
 */
DebugService.logFPS = (value) => {
    const logFPS = document.querySelector('#logFPS');
    logFPS.innerHTML = "" + value;
}



export default DebugService