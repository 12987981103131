
import * as PIXI from 'pixi.js';
import ResourceList from "../../../services/ResourceList";
import SpriteCommon from "../common/SpriteCommon";

class WavesEffect extends PIXI.Container {
    constructor(){
        super()
        this.eventMode = "none";
        this.interactiveChildren = false;

        this.wave1 = new SpriteCommon(ResourceList.MSC_WAVES_EFFECT);
        this.wave2 = new SpriteCommon(ResourceList.MSC_WAVES_EFFECT);
        this.wave3 = new SpriteCommon(ResourceList.MSC_WAVES_EFFECT);

        this.wave1.anchor.set(0.5, 0.5);
        this.wave2.anchor.set(0.5, 0.5);
        this.wave3.anchor.set(0.5, 0.5);

        this.addChild(this.wave1);
        this.addChild(this.wave2);
        this.addChild(this.wave3);
        this.visible = false;
    }

    update(t) {
        const ph = t / 10;

        const c1 = ph % (Math.PI / 2)
        const c2 = (ph + Math.PI / 3) % (Math.PI / 2)
        const c3 = (ph + 2 * Math.PI / 3) % (Math.PI / 2)

/*         this.wave1.alpha = 1 - Math.abs(Math.sin(c1) * Math.sin(c1));
        this.wave2.alpha = 1 - Math.abs(Math.sin(c2) * Math.sin(c2));
        this.wave3.alpha = 1 - Math.abs(Math.sin(c3) * Math.sin(c3)); */
 
        this.wave1.alpha = 1 - Math.abs(Math.sin(c1));
        this.wave2.alpha = 1 - Math.abs(Math.sin(c2));
        this.wave3.alpha = 1 - Math.abs(Math.sin(c3)); 

/*         this.wave1.alpha = 1 - Math.abs(Math.sin(c1) * Math.sin(c1) * Math.sin(c1) * Math.sin(c1));
        this.wave2.alpha = 1 - Math.abs(Math.sin(c2) * Math.sin(c2) * Math.sin(c2) * Math.sin(c2));
        this.wave3.alpha = 1 - Math.abs(Math.sin(c3) * Math.sin(c3) * Math.sin(c3) * Math.sin(c3)); */

        const sc1 = 0.7 + Math.abs(Math.sin(c1)) * 1.5;
        const sc2 = 0.7 + Math.abs(Math.sin(c2)) * 1.5;
        const sc3 = 0.7 + Math.abs(Math.sin(c3)) * 1.5;

        this.wave1.scale.set(sc1);
        this.wave2.scale.set(sc2);
        this.wave3.scale.set(sc3);

    }


}

export default WavesEffect
