import * as PIXI from 'pixi.js';
import { AppConfig } from './config/AppConfig';
import Game from "./Game";
import { extendPIXI } from "./view/utils/ExtendPIXI"
import DebugService from './services/DebugService';


AppConfig.updateSize(window.innerWidth, window.innerHeight);
const { gameWidth, gameHeight } = AppConfig.settings;

window.PIXI =  PIXI

extendPIXI()

const options = {
    width: gameWidth,
    height: gameHeight,
    background: 0xc00000,
    // antialias: true,
    transparent: true,
    resolution: 2,
};
const app = new PIXI.Application(options);

window['globalThis']['__PIXI_APP__'] = app;

const onResize = () => {
    app.renderer.resize(window.innerWidth, window.innerHeight);
    AppConfig.updateSize(window.innerWidth, window.innerHeight);
}

const disableDoubTapZoom = () => {
    let drags = new Set() //set of all active drags
      document.addEventListener("touchmove", function(event){
        if(!event.isTrusted)return //don't react to fake touches
        Array.from(event.changedTouches).forEach(function(touch){
          drags.add(touch.identifier) //mark this touch as a drag
        })
      })
      document.addEventListener("touchend", function(event){
        if(!event.isTrusted)return
        let isDrag = false
        Array.from(event.changedTouches).forEach(function(touch){
          if(drags.has(touch.identifier)){
            isDrag = true
          }
          drags.delete(touch.identifier) //touch ended, so delete it
        })
        if(!isDrag && document.activeElement == document.body){
          //note that double-tap only happens when the body is active
          event.preventDefault() //don't zoom
          event.stopPropagation() //don't relay event
          event.target.focus() //in case it's an input element
          event.target.click() //in case it has a click handler
          event.target.dispatchEvent(new TouchEvent("touchend",event))
          //dispatch a copy of this event (for other touch handlers)
        }
      });
}

const init = () => {
    disableDoubTapZoom();
    window.addEventListener('resize', onResize);
    const game = new Game(app);
    AppConfig.game  =  game;
    app.stage.addChild(game);
    onResize();
    document.body.appendChild(app.view);

    const reportMessages = document.querySelector('#logMessages');
    const domReport = document.getElementById('reportMessages');
    domReport.style.display = AppConfig.isDebug ? "block" : "none";
    if (AppConfig.isDebug) {
        // AppConfig.gameSettings.counDownStepDuration = 0.01;
    }
    return game
};

export { app, init };
