import EItemsID from "../model/EItemsID";

class SoundRecord {
    constructor(src, volume, loop = false, cachedDuration = 0) {
        this.src = src;
        this.volume = volume;
        this.loop = loop;
        this.cachedDuration = cachedDuration;
    }
}

class SoundData {

    static CATCH_PLUS10 = "catch" + EItemsID.PLUS10;
    static CATCH_PLUS20 = "catch" + EItemsID.PLUS20;
    static CATCH_MINUS_N_POINTS = "catch" + EItemsID.MINUS_N_POINTS;
    static CATCH_MINUS_N_SECONDS = "catch" + EItemsID.MINUS_N_SECONDS;
    static CATCH_PLUS_N_SECONDS = "catch" + EItemsID.PLUS_N_SECONDS;
    static CATCH_MAGNET = "catch" + EItemsID.MAGNET;
    static CATCH_SPEED_UP = "catch" + EItemsID.SPEED_UP;

    static GET_SCORE_MULTIPLAYER = "getScoreMultiplayer";

    static LOOP_BG_MUSIC = "loopBgMusic";
    static LOOP_MAGNET = "loopMagnet";
    static LOOP_SPEEDUPT = "loopSpeedUp";

    static MSC_COUNDDOWN = "countDown";
    static MSC_COUNDDOWN_GO = "countDownGo";
    static MSC_START = "start";
    static MSC_FINISH = "finish";

    static SONG = "song";


    static data = {

        // [SoundData.SONG]: new SoundRecord("./assets/sounds/song_yellow.mp3", 0.4, false, 158.82448979591837),
        [SoundData.SONG]: new SoundRecord("./assets/sounds/song2.mp3", 0.7, true),
        
        // [SoundData.MSC_COUNDDOWN]: new SoundRecord("./assets/sounds/count.mp3", 1,),
        // [SoundData.MSC_COUNDDOWN_GO]: new SoundRecord("./assets/sounds/go1.mp3", 0.4,),
        // [SoundData.MSC_START]: new SoundRecord("./assets/sounds/start2.mp3", 0.7,),
        // [SoundData.MSC_FINISH]: new SoundRecord("./assets/sounds/finish.mp3", 0.5,),

        
    };
}

export default SoundData