import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';
import GameScreen from '../screens/GameScreen';
class SongPreloader extends PIXI.Container {
    /**
     * @param {GameScreen} gameScreen 
     */
    constructor(gameScreen) {
        super();
        this.gameScreen = gameScreen;
        this.soundManager = this.gameScreen.soundManager;
        this.text = "loading";
        this.label = new Text (this.text + "...", {
            fontFamily: 'SecondMusicPlayer',
            fontSize: 16,
            lineHeight: 23,
            fill: '#FFFFFF',
            align: 'center'
        });
        // this.caption.anchor.set(0.5, 0.5);
        this.label.x = - this.width / 2;
        this.label.y = - this.height / 2;
        this.addChild(this.label);
        this.alpha = 0;

        this.updatePreloader = () => {
            let caption = this.label;
            if (caption === this.text + "...") {
                caption = this.text + ".";
            } else if (caption === this.text + "..") {
                caption = this.text + "...";
            } else if (caption === this.text + ".") {
                caption = this.text + "..";
            }
            caption.x = - this.width / 2;
            caption.y = - this.height / 2;

        }
        this.preloaderIntervalID = setInterval(this.updatePreloader, 1000);

        this.clear = () => {
            clearInterval(this.preloaderIntervalID);
        }

    }


}

export default SongPreloader;