import { Assets, Sprite } from 'pixi.js';
import ResourceList from "./ResourceList";
class ResourceService {
}
ResourceService.init = (onSuccess) => {
    Assets.load(ResourceList.LIST).then(() => onSuccess());
}; 


ResourceService.getTexture = (resourceName) => {
    if (resourceName === undefined) {
        const stackTrace = (new Error()).stack;
        console.error('undefined texture name was called from:', stackTrace);
        return null;
    }
    try {
        return Assets.get(resourceName);
    } catch (error) {
        // Capture stack trace
        const stackTrace = (new Error()).stack;
        console.error(`Error retrieving texture for resource '${resourceName}':`, error);
        console.error('Stack trace:', stackTrace);
        return null;
    }
};


ResourceService.getSprite = (resourceName) => {
    return new Sprite(ResourceService.getTexture(resourceName));
};
ResourceService.getSpriteSheet = (resourceName) => {
    return Assets.get(resourceName);
};
ResourceService.getSound = (resourceName) => {
    return Assets.get(resourceName);
};
export default ResourceService;
