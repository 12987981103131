{
  "name": "music-player",
  "version": "0.3.81",
  "description": "Music Player",
  "scripts": {
    "clear": "rm -rf dist/* && rm -rf .parcel-cache/*",
    "start": "PORT=8080 parcel src/index.html --open 'http://localhost:8080/?totalrotates=44&playafterloaded=1'  --https",
    "build": "node update_version.js && npm run clear && parcel build src/index.html --detailed-report --public-url ./ && npm run zip",
    "zip": "VERSION=$(node -pe \"require('./package.json').version\") && cd dist && zip -r ../../music_player_$VERSION.zip ./ && cd ..",
    "incr": "node update_version.js",
    "publish": "npm run build && bash ../publish_music_player.sh"
  },
  "author": "ushan",
  "license": "MIT",
  "dependencies": {
    "@pixi/filter-zoom-blur": "^5.1.1",
    "crypto-js": "^4.2.0",
    "gsap": "^3.6.1",
    "howler": "^2.2.4",
    "mini-signals": "^2.0.0",
    "pixi.js": "^7.3.2"
  },
  "devDependencies": {
    "@parcel/config-default": "2.9.2",
    "parcel": "^2.10.1",
    "parcel-plugin-static-files-copy": "file:../..",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "ts-loader": "^8.0.11",
    "typescript": "^4.0.5"
  },
  "staticFiles": {
    "staticPath": "src/assets",
    "staticOutPath": "assets",
    "watcherGlob": "**"
  },
  "keywords": []
}