import * as PIXI from 'pixi.js';
import { Utils } from '../../utils/Utils'
export default class SingleParticle extends PIXI.Sprite {
  /**
   *
   * @param emitter
   */
  constructor(emitter) {
    super(PIXI.Texture.WHITE)
    this.emitter = emitter
    this.alive = false
    this.anchor.set(0.5)
    this.width = 50
    this.height = 50
  }

  initParticle(id) {
    this.id = id
    this.alive = true
    this.visible = true
  }

  setData(data = {}) {
    this.texture = data.texture ?? PIXI.Texture.EMPTY
    this.width = data.scale
    this.height = data.scale

    this.origin = data.origin
    this.textureOverLife = data.textureOverLife ?? null
    this.animatedTextureFPS = data.animatedTextureFPS ?? null
    this.animatedTextureRandom = Utils.randomInt(data.animatedTextureRandom) ?? 0
    this.color = data.color ?? 0xffffff
    this.colorOverLife = data.colorOverLife ?? null
    this.lifeMax = data.life || 1
    this.lifeValue = this.lifeMax
    this.vector = data.vector ?? new PIXI.Point()
    this.velocity = this._velocity = data.velocity ?? 0
    this.velocityOverLife = data.velocityOverLife ?? null
    this._alpha = data.alpha ?? 1
    this.alphaOverLife = data.alphaOverLife ?? null
    this._scale = data.scale
    this.scaleOverLife = data.scaleOverLife ?? null
    this.oriented = data.oriented ?? false;
    this.rotationVelocity = this._rotationVelocity = data.rotationVelocity ?? 0
    this.rotationVelocityOverLife = data.rotationVelocityOverLife ?? null
    this.force = data.force ?? 0
    this.forceOverLife = data.forceOverLife ?? null
    this.forceVectors = data.forceVectors ?? []
    this._twirlForce = this.twirlForce = data.twirlForce ?? 0
    this.twirlForceOverLife = data.twirlForceOverLife ?? null

    this.x = data.x
    this.y = data.y
    this.scale.set(this._scale)
    this.rotation = data.rotation ?? 0
    this.alpha = this._alpha

    this.blendMode = data.blendMode ?? PIXI.BLEND_MODES.NORMAL;
  }

  update(dt, timeScale = 1) {
    if (!this.alive) {
      return
    }
    if (this.lifeValue <= 0) {
      this.kill()
    }
    this.timeScale = timeScale
    this.deltaTime = dt * 50
    this.lifeValue -= dt * timeScale
    const hp = Utils.clamp(1 - this.lifeValue / this.lifeMax)

    if (this.velocityOverLife) {
      this.velocity = this._velocity * Utils.lerpArray(hp, this.velocityOverLife)
    }
    if (this.rotationVelocityOverLife) {
      this.rotationVelocity = this._rotationVelocity * Utils.lerpArray(hp, this.rotationVelocityOverLife)
    }
    if (this.textureOverLife) {
      this.texture = this.textureOverLife[Math.floor((this.lifeValue * this.animatedTextureFPS + this.animatedTextureRandom) % this.textureOverLife.length)]
    }
    if (this.alphaOverLife) {
      this.alpha = this._alpha * Utils.lerpArray(hp, this.alphaOverLife)
    }
    if (this.scaleOverLife) {
      this.scale.set(this._scale * Utils.lerpArray(hp, this.scaleOverLife))
    }
    if (this.forceOverLife) {
      this.force = Utils.lerpArray(hp, this.forceOverLife)
    }
    if (this.rotationVelocity) {
      this.rotation += this.rotationVelocity * 0.01 * this.timeScale * this.deltaTime
    }
    if (this.twirlForceOverLife) {
      this.twirlForce = this._twirlForce * Utils.lerpArray(hp, this.twirlForceOverLife)
    }

    //required some color lerp improvements
    // if (this.colorOverLife) {
    //   this.color = Utils.lerpArray(hp, this.colorOverLife)
    // }

    this.tint = this.color

    this.updateParticleTransform()
  }

  updateParticleTransform() {
    this.position.add(this.vector.clone().multiplyScalar(this.velocity * this.timeScale * this.deltaTime))

    if (this.twirlForce) {
      const newAngle = this.position.angleTo(this.origin) + Math.PI / 1.5 * Math.sign(this.twirlForce)
      const dist = this.position.distance(this.origin)
      const force = this.velocity * this.deltaTime * this.timeScale * Math.abs(this.twirlForce) * (dist * 0.01)
      this.position.add(Math.cos(newAngle) * force, Math.sin(newAngle) * force)
    }

    this.forceVectors.forEach((el) => {
      this.position.add(el.clone().multiplyScalar(this.force * this.timeScale * this.deltaTime))
    })

          if (this.oriented) {
                    this.rotation = Utils.angleFromXY(0, 0, this.vector.x, this.vector.y)
          }
  }

  kill() {
    this.alive = false
    this.visible = false
    this.emitter?.removeParticle(this)
  }
}
