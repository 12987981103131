import EEffectNames from "../components/effects/EEffectNames";
import GameScreen from "../screens/GameScreen";

class EffectsEditorDOM {
    /**
     * 
     * @param {GameScreen} screen 
     */
    constructor(screen) {
        // super(ResourceList.RULES);
        this.screen = screen;
        this.emitter = this.screen.emitter;
        this.topEmitter = this.screen.topEmitter;
        this._visible = undefined;
        this.domLink = document.getElementById('effectsEditor');
        // this.closeBtnDOM = document.querySelector('.close-btn');

        // this.currentEffect = this.emitter.emmitterData[EEffectNames.STARS];
        this.currentEffect = this.topEmitter.emmitterData[EEffectNames.SCORES_1];

        const properties = Object.keys(this.currentEffect).map(key => {
            const value = this.currentEffect[key];
            if (Array.isArray(value) && value.length === 2 && typeof value[0] === 'number') {
                return { label: key, value: value, type: 'range' };
            } else if (typeof value === 'number') {
                return { label: key, value: value, type: 'single' };
            }
            return null; // Ignoring non-numeric and non-range arrays
        }).filter(Boolean);


        if (this.domLink && this.closeBtnDOM) {
            this.closeBtnDOM.cursor = "pointer";
            // this.visible = this.gameModel.communictionService.shouldShowTutorial;

            this.closeBtnDOM.addEventListener('pointerup', () => {
                this.gameModel.showRules = false;
                this.visible = false;
            });

            this.gameModel.rulesStatusUpdated.add((shouldShow) => {
                this.visible = shouldShow;
            })
        }

        this.generateEffectEditorUI(this.domLink, properties);
    }

    generateEffectEditorUI(divElement, properties) {
        properties.forEach(prop => {
            const propertyBlock = document.createElement('div');
            propertyBlock.className = 'property-block';

            // Create label element
            const label = document.createElement('label');
            label.innerText = prop.label;

            if (prop.type === 'single') {
                // Single input for number value
                const input = document.createElement('input');
                input.type = 'number';
                input.value = prop.value;

                // Add event listener for single input
                input.addEventListener('input', (event) => {
                    this.currentEffect[prop.label] = parseFloat(event.target.value);
                    this.applyChanges(this.currentEffect);
                });

                propertyBlock.appendChild(label);
                propertyBlock.appendChild(input);
            } else if (prop.type === 'range') {
                // Create range inputs for array values
                const rangeDiv = document.createElement('div');
                rangeDiv.className = 'range-input';

                const inputMin = document.createElement('input');
                inputMin.type = 'number';
                inputMin.value = prop.value[0];

                const inputMax = document.createElement('input');
                inputMax.type = 'number';
                inputMax.value = prop.value[1];

                const step = this.calculateStep(prop.value[0], prop.value[1]);
                inputMin.step = step;
                inputMax.step = step;

                // Add event listener for both inputs in the range
                inputMin.addEventListener('input', (event) => {
                    this.currentEffect[prop.label][0] = parseFloat(event.target.value);
                    this.applyChanges(this.currentEffect);
                });

                inputMax.addEventListener('input', (event) => {
                    this.currentEffect[prop.label][1] = parseFloat(event.target.value);
                    this.applyChanges(this.currentEffect);
                });

                rangeDiv.appendChild(inputMin);
                rangeDiv.appendChild(inputMax);

                propertyBlock.appendChild(label);
                propertyBlock.appendChild(rangeDiv);
            }

            // Append the property block to the main div
            divElement.appendChild(propertyBlock);
        });
    }

    applyChanges(updatedEffects) {
        console.log('Applying effect settings:', updatedEffects);
    }

    calculateStep(min, max) {
        const range = max - min;
        const magnitude = Math.pow(10, Math.floor(Math.log10(range))); // Get order of magnitude
        const step = magnitude / 10; // Adjust step based on magnitude
        return step;
    }
   

    /**
     * @type {boolean}
     */
    get visible(){ return this._visible}

    set visible(value) {
        if (this._visible === value) return
        this._visible === value;
        this.domLink.style.display = value ? "flex" : "none";
    }

}

export default EffectsEditorDOM