
export class Utils {

  static clamp(a, min = 0, max = 1) {
    return Math.max(Math.min(min, max), Math.min(a, Math.max(min, max)))
  }

  static lerp(t, a, b) {
    return b * t + a * (1 - t)
  }

  static inverseLerp(v, min, max) {
    const n = Utils.clamp(v, min, max)
    return (n - min) / (max - min)
  }

  static remap(v, min, max, a, b) {
    return Utils.lerp(Utils.inverseLerp(v, min, max), a, b)
  }

  static lerpArray(t, arr) {
    const time = t * (arr.length - 1)
    const timeFrom = Math.floor(time)
    const timeTo = Math.ceil(time)
    if (timeFrom === timeTo) {
      return arr[timeFrom]
    }
    return Utils.remap(time, timeFrom, timeTo, arr[timeFrom], arr[timeTo])
  }

  static random(from, to) {
    if (from === undefined) {
      return Math.random()
    }
    if (to === undefined) {
      return Math.random() * from
    }
    else {
      return from + Math.random() * (to - from)
    }
  }

  static randomInt(from, to) {
    const min = Math.ceil(from)

    if (to === undefined) {
      return Math.trunc(Math.random() * from)
    }

    const max = Math.floor(to)

    return Math.trunc(Math.random() * (max - min + 1)) + min
  }

  static pick(arr) {
    return arr[Math.trunc(Math.random() * arr.length)]
  }

  static randomSign() {
    return Utils.pick([-1, 1])
  }

  /**
   * Calculates distance between two points [x1, y1] and [x2, y2]
   * @param x1 {number}
   * @param y1 {number}
   * @param x2 {number}
   * @param y2 {number}
   * @returns {number}
   */
  static distanceXY(x1 = 0, y1 = 0, x2 = 0, y2 = 0) {
    const w = Math.max(x1, x2) - Math.min(x1, x2)
    const h = Math.max(y1, y2) - Math.min(y1, y2)
    return Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2))
  }

  /**
   * Calculates distance between two Point {x, y} objects
   * @param {{x, y}} pointA
   * @param {{x, y}} pointB
   */
  static distance(pointA, pointB) {
    return Utils.distanceXY(pointA.x, pointA.y, pointB.x, pointB.y)
  }

  static angleFromXY(x1, y1, x2, y2, min = false) {
    let ang = Math.atan2(y2 - y1, x2 - x1)
    return min ? Math.min(ang,  Math.abs(Math.PI * 2 - ang)) : ang
  }

  /**
   * Multiplies objects' scale b
   * @param object
   * @param x
   * @param y
   */
  static flipScale(object, x, y) {
    object.scale.x *= x ? -1 : 1
    object.scale.y *= y ? -1 : 1
  }


  static rescaleMaxWidth(obj, width) {
    obj.scale.set(1)
    const newScale = Math.min(width / obj.width, 1)
    obj.scale.set(newScale)
  }

  /**
   * Converts numbers into string with fixed characters (5 of 134 -> '005')
   * @param i
   * @param max
   */
  static fillZero(i, max) {
    const l = `${max}`.length
    return new Array(l - `${i}`.length).fill('0').join('') + i
  }

}
