import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { AppConfig } from '../../config/AppConfig';
import GameModel, { EGameStates } from '../../model/GameModel';
import EMessages from '../../services/EMessages';
import Countdown from '../components/CountDown';
import Disk from '../components/Disk';
import Wave from '../components/Wave';
import EEffectNames from '../components/effects/EEffectNames';
import Fireworks from '../components/effects/Fireworks';
import ParticleEmitterFactory from '../components/effects/ParticleEmitterFactory';
import WavesEffect from '../components/effects/WavesEfffect';
import PanelControls from '../components/panels/PanelControls';
import PanelInfo from '../components/panels/PanelInfo';
import EffectsEditorDOM from '../doms/EffectsEditorDOM';
import ReglesDOM from '../doms/ReglesDOM';
import PraiseWords from '../components/PraiseWords';
import SongPreloader from '../components/SongPreloader';
import DebugService from '../../services/DebugService';
import ParticleEmitter from '../components/effects/ParticleEmitter';


class GameScreen extends PIXI.Container {
    static isMouseDown = false;
    /**
     * @param {PIXI.Application} app
     * @param {GameModel} gameModel
     */
    constructor(app, gameModel) {
        super();
        this.phase = 0;
        this.eventMode = `dynamic`;
        const { gameWidth, gameHeight, vPosDisk } = AppConfig.settings;

        this.app = app;
        this.gameModel = gameModel;
        this.soundManager = gameModel.soundManager;
        // this.acitvateSounds();

        this.disk = new Disk(this, gameModel);
        this.songPreloader = new SongPreloader(this);
        this.wave = new Wave(gameModel, this);

        this.panelInfo = new PanelInfo(gameModel, this);
        this.emitter = ParticleEmitterFactory.initEmitter(this);
        this.topEmitter = ParticleEmitterFactory.initTopEmitter(this);
        this.wavesEffect = new WavesEffect();
        this.fireworks = new Fireworks(this);
        this.fireworks.allowNewParticles = false;

        this.countdown = new Countdown(this.gameModel, this);
        this.rulesDOM = new ReglesDOM(this.gameModel);
        this.effectsEditor = new EffectsEditorDOM(this);
        this.panelControls = new PanelControls(gameModel, this);

        this.praiseWords = new PraiseWords(this.gameModel, this);

        this.t = 0;

        this.addElements = () => {
            const { gameWidth, gameHeight, vPosDisk } = AppConfig.settings;

            this.addChild(this.topEmitter);
            this.addChild(this.wavesEffect);
            this.addChild(this.fireworks);
            this.addChild(this.disk);
            this.addChild(this.songPreloader);
            this.addChild(this.praiseWords);
            this.addChild(this.wave);
            //   this.addChild(this.emitter);
            //   this.fireworks.addEmmiter(this.disk, 0, 0);
            this.fireworks.setEmmiterPos(gameWidth / 2, gameHeight * vPosDisk);
            //   this.addChild(this.panelInfo);
            

            this.panelInfo.x = 10;
            this.panelInfo.y = 20;

            this.updateScores();

            //this.addChild(this.touchArea);
            this.addChild(this.countdown);
            this.addChild(this.panelControls);
            this.countdown.on('countdownStarted', () => {


            });
            this.countdown.on('countdownComplete', () => {
                    // this.soundManager.preloadAllSounds();
                    // this.gameModel.sendMessage(EMessages.SND_GAME_START)
                    

                    if (!this.soundManager.songIsLoaded) {
                        this.soundManager.preloadAllSounds();
                        this.acitvateSounds();
                        gsap.to(this.songPreloader, { alpha: 1, duration: 0.5});
                        gsap.to(this.disk, { alpha: 0.3, duration: 0.5}); 
                        
                    } else {
                        this.disk.alpha = 1;
                    }
                    // this.start();

                    // this.gameModel.sendMessage(EMessages.SND_GAME_START);
                    this.start();

            });

            this.onResize();

        };

        this.count = 1;

        this.updateScores = (item, scores) => {
        };


        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                this.soundManager.resumeGameSounds();
                ParticleEmitter.isPlaying = true;

            } else {
                this.soundManager.pauseGameSounds();
                ParticleEmitter.isPlaying = false;
            }
        };


        this.onGameRestarted = (item) => {
            this.countdown.resetCountDown();
            this.disk.alpha = 1;
            // this.disk.rotation = 0;
        };


        this.effectIndex = 0;
        this.repeatNum = 0;
        this.restartEffect = () => {
            const { gameWidth, gameHeight, vPosDisk } = AppConfig.settings;
            this.repeatNum ++;
            if (this.repeatNum >= 50) {
                this.effectIndex++;
                if (this.effectIndex >= EEffectNames.list.length) {
                    this.effectIndex = 0;
                    const s = 0;
                }
                this.repeatNum = 0;
            }
            const effectName = EEffectNames.list[this.effectIndex];
            // const effectName = EEffectNames.list[0];
            this.emitter.start(gameWidth / 2, gameHeight * vPosDisk,  12, effectName);
        }
        this.topEmitter.start(gameWidth / 2, gameHeight * vPosDisk, 50, EEffectNames.SCORES_1);


        this.onSongPlayingStatusChanged = (isPlaying) => {


        }

        this.onSongLoaded = () => {
            DebugService.logIncr('Song is loaded');
            if (this.songPreloader) {
                gsap.to(this.songPreloader, { alpha: 0.2, duration: 0.5, onComplete: () => {
                    this.songPreloader.clear();
                    this.removeChild(this.songPreloader);
                    this.songPreloader = undefined;
                }});

            }

        }
        
        this.onIsRotatingUpdated = (value) => {
            this.fireworks.allowNewParticles = value;
            if (value) {
                this.wavesEffect.visible = true;
                this.wavesEffect.alpha = 0;
                gsap.to(this.wavesEffect, { alpha: 1, duration: 0.9});
            } else {
                gsap.to(this.wavesEffect, { alpha: 0, duration: 0.9});
            }
        }

        this.gameModel.scoreUpdated.add(this.updateScores);
        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);
        this.gameModel.gameRestarted.add(this.onGameRestarted);
        this.gameModel.isRotatingUpdated.add(this.onIsRotatingUpdated);
        this.soundManager.onSongLoadedSignal.add(this.onSongLoaded);
        

        this.init();
        AppConfig.sizeUpdated.add(this.onResize.bind(this));

        setInterval(this.restartEffect, 1000);
    }

    init() {
        this.addElements();
        this.step = 0;
        this.deltasum = 0;
        this.app.ticker.add((delta) => {


        });

        window.addEventListener('keydown', (e) => {
            if (e.code === 'ArrowRight') {
                this.gameModel.registerMoveCart(false);
            } else if (e.code === 'ArrowLeft') {
                this.gameModel.registerMoveCart(true);
            };
        });

        window.addEventListener('mousedown', function(event) {
            if (event.button === 0) {
                GameScreen.isMouseDown = true;
            }
        });
        
        window.addEventListener('mouseup', function(event) {
            if (event.button === 0) {
                GameScreen.isMouseDown = false;
            }
        });



    };


    acitvateSounds() {
        // this.soundManager.init();
        if (!this.soundManager.initialized) {
            //this.acitvateSounds();
            this.soundManager.init();
        }
    }

    start() {
        this.gameModel.startGame();
    }

    animate (delta = 0) {

        const { gameWidth, gameHeight, vPosDisk } = AppConfig.settings;

        if (this.gameModel.gameState !== EGameStates.playing && this.gameModel.gameState !== EGameStates.ready) return
        // this.t += delta;
        this.t += 0.1;
        this.phase += 0.01
        this.step++;
        this.deltasum += delta;
        if (this.step % 60 === 0) {
            this.deltasum = 0;
        }

        // const angle = this.disk.fingerAngle;
        // const posX = gameWidth / 2 + Math.sin(angle) * 230;
        // const posY = gameHeight / 2 + Math.cos(angle) * 230;
        const posX = this.disk.fingerPoint.x;
        const posY = this.disk.fingerPoint.y;
        this.fireworks.setEmmiterPos(gameWidth / 2, gameHeight * vPosDisk);

        this.fireworks.update();
        this.wavesEffect.update(this.t);
        this.disk.update(delta)
    };

    onResize(item) {
        const { gameWidth, gameHeight, vPosDisk } = AppConfig.settings;
        this.countdown.position.set(gameWidth / 2, gameHeight / 2);

        if (this.speedUpEffectConfig && this.speedUpEffectConfig.origin) {
            this.speedUpEffectConfig.origin.x = gameWidth / 2;
            this.speedUpEffectConfig.origin.y = gameHeight * vPosDisk;
        }

        this.wavesEffect.x = gameWidth / 2;
        this.wavesEffect.y = gameHeight * vPosDisk;

        if (this.songPreloader) {
            this.songPreloader.x = gameWidth / 2;
            this.songPreloader.y = gameHeight * vPosDisk;
        }
    }
    
    
}
export default GameScreen;
