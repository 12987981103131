
class ResourceList {
}


ResourceList.START_BUTTON = `./assets/start-button.png`;
ResourceList.HELLO_TITLE = `./assets/hello-title.png`;
ResourceList.DISK = `./assets/disk.png`;


ResourceList.COUNTDOWN_1 = `./assets/countdown/count1.png`;
ResourceList.COUNTDOWN_2 = `./assets/countdown/count2.png`;
ResourceList.COUNTDOWN_3 = `./assets/countdown/count3.png`;
ResourceList.COUNTDOWN_GO = `./assets/countdown/count-go.png`;

ResourceList.MSC_BTN_MUTE = `./assets/misc/btn_mute.png`;
ResourceList.MSC_BTN_UNMUTE = `./assets/misc/btn_unmute.png`;
ResourceList.MSC_BTN_CLOSE = `./assets/misc/btn_close.png`;
ResourceList.MSC_COIN = `./assets/misc/coin.png`;

ResourceList.EFFECTS = `./assets/effects.json`;

ResourceList.ITEM_MAGNET = `./assets/items/item-magnet.png`;
ResourceList.ITEM_SPEEDUP = `./assets/items/item-speedup.png`;
ResourceList.ITEM_TIMEPLUS = `./assets/items/item-timeplus.png`;
ResourceList.ITEM_TIMEMINUS = `./assets/items/item-timeminus.png`;
ResourceList.ITEM_BAD = `./assets/items/item-bad.png`;

ResourceList.MSC_COIN = `./assets/misc/coin.png`;
ResourceList.MSC_STAR = `./assets/misc/star.png`;
ResourceList.MSC_MINUS = `./assets/misc/minus.png`;
ResourceList.MSC_PLUS = `./assets/misc/plus.png`;
ResourceList.MSC_STAR4 = `./assets/misc/star4.png`;
ResourceList.MSC_DROP = `./assets/misc/drop.png`;
ResourceList.MSC_SHIMMER = `./assets/misc/shimmer.png`;
ResourceList.MSC_SPEEDLINE = `./assets/misc/speedline.png`;
ResourceList.MSC_FADE_CIRCLE = `./assets/misc/fade_circle.png`;
ResourceList.MSC_CIRCLE = `./assets/misc/circle.png`;

// ResourceList.MSC_WAVES_EFFECT = `./assets/misc/magnet-effect.png`;
ResourceList.MSC_WAVES_EFFECT = `./assets/misc/wave-effect2.png`;

ResourceList.MSC_CART_CLICKER = `./assets/misc/cart-click.png`;
ResourceList.MSC_CART_CLICKER_LEFT = `./assets/misc/cart-click-left.png`;
ResourceList.MSC_CART_CLICKER_CENTER = `./assets/misc/cart-click-center.png`;
ResourceList.MSC_CART_CLICKER_RIGHT = `./assets/misc/cart-click-right.png`;
ResourceList.MSC_BTN_REGLES = `./assets/misc/btn-regles.png`;
ResourceList.ARROW = `./assets/misc/arrow.png`;


ResourceList.NOTE_1 = `./assets/scores/1.png`;
ResourceList.NOTE_2 = `./assets/scores/2.png`;
ResourceList.NOTE_3 = `./assets/scores/3.png`;
ResourceList.NOTE_4 = `./assets/scores/4.png`;
ResourceList.DOT = `./assets/scores/dot.png`;

ResourceList.WAVE = `./assets/wave.png`;



ResourceList.CUSTOM_RESOURCES = [];

ResourceList.LIST = [

    // ResourceList.HIT_AREA,

    ResourceList.START_BUTTON,
    ResourceList.HELLO_TITLE,
    ResourceList.DISK,

    ResourceList.COUNTDOWN_1,
    ResourceList.COUNTDOWN_2,
    ResourceList.COUNTDOWN_3,
    ResourceList.COUNTDOWN_GO,

    ResourceList.MSC_BTN_MUTE,
    ResourceList.MSC_BTN_UNMUTE,
    ResourceList.MSC_BTN_CLOSE,
    ResourceList.MSC_COIN,
    ResourceList.MSC_WAVES_EFFECT,

    ResourceList.MSC_CART_CLICKER,
    ResourceList.MSC_CART_CLICKER_LEFT,
    ResourceList.MSC_CART_CLICKER_CENTER,
    ResourceList.MSC_CART_CLICKER_RIGHT,
    ResourceList.MSC_BTN_REGLES,
    ResourceList.ARROW,

    ResourceList.NOTE_1,
    ResourceList.NOTE_2,
    ResourceList.NOTE_3,
    ResourceList.NOTE_4,
    ResourceList.DOT,

    ResourceList.WAVE,
  
    ...ResourceList.CUSTOM_RESOURCES,
];


// const skinArray = ResourceList["LIST_GAME" + AppConfig.gameID]

// ResourceList.LIST = ResourceList.LIST.concat(skinArray);


export default ResourceList;
