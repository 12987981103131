import { AppConfig } from "../config/AppConfig";
import { MiniSignal } from "mini-signals";
import CommunicationService from "../services/CommunicationService";
import GameStat from "./GameStat";
import EMessages from "../services/EMessages";
import UUIDLoader from "../services/UUIDLoader";
import DebugService from "../services/DebugService";
import { SoundManager } from "../services/SoundManager";
import GameTimer from "./GameTimer";

export const EGameStates = Object.freeze({"stop":1, "playing":2, "pause":3, "ready":4});

const { magnetItemsCount} = AppConfig.settings;

class GameModel {
    static _instance;
    static get instance() {
        return GameModel._instance;
    }
    constructor() {
        const { initialSpeed, initialTimeLeft } = AppConfig.gameSettings;
        GameModel._instance = this;
        this.communictionService = new CommunicationService(this);
        this.avanceSongTimeUpdated = new MiniSignal();
        this.songPositionUpdated = new MiniSignal();
        this.scoreUpdated = new MiniSignal();
        this.gameStateUpdated = new MiniSignal();
        this.gameRestarted = new MiniSignal();
        this.rulesStatusUpdated = new MiniSignal();
        this.isRotatingUpdated = new MiniSignal();
        this.gameProgressUpdated = new MiniSignal();

        this._gameState = EGameStates.ready;
        this.gameStat = new GameStat();
        this._speed = initialSpeed;
        this._scores = 0;
        this._avanceAngle = 0;
        this._avanceSongPercent = 0;
        this._trackedAngle = 0;
        this.lastTrackedAngle = 0;
        this._gameProgress = 0;
        this._songPosition = 0;
        this._showRules = false;
        this._isAutoPlay = false;
        this.emtpyRowsCount = 0;
        this._isRotating = false;
        this.previousAvanceAngle = 0;

        this.gameTimer = new GameTimer();

        this.playAfterStopIntervalID;

        this.soundManager = new SoundManager(this);

    }

    get scores() { return this._scores }
    set scores(value) {
        if (value == this._scores) return
        const increment = value - this._scores;
        // this._scores = value;
        if (value >= 0) {
            this._scores = value;
        } else {
            this._scores = 0;
        }
        if (value >= 1000) {
            this._scores = 1000;
            this.finishGame();
        } 
        this.scoreUpdated.dispatch(this.lastItem, increment);
    }

    get avanceSongPercent() { return this._avanceSongPercent }
    get avanceAngle() { return this._avanceAngle }
    set avanceAngle(value) {
/*         const diff = value - this.previousAvanceAngle;
        console.log("avanceAngle:" + diff);
        this.previousAvanceAngle = value */
        //TODO change set gameProgress to trachedAngle
        
        const startAfterAvanceSong = AppConfig.startAfterAvanceSong;
        this.songPosition = this.soundManager.getSongPosition();
        
        const turns = AppConfig.gameSettings.totalrotates;
        let avancePercentTotal =  Math.abs(value / (Math.PI * turns * 2));

        if (avancePercentTotal <= this._songPosition + AppConfig.maxLoaded) {
            this._avanceAngle = value;
        } else {
            avancePercentTotal = this._songPosition + AppConfig.maxLoaded;
            this._avanceAngle = Math.PI  * 2 * turns * avancePercentTotal;
        }

        this._avanceSongPercent = avancePercentTotal;

        if (this._isAutoPlay) {
            if (this._songPosition + startAfterAvanceSong <= this.avanceSongPercent ||
                this._songPosition >= 1 - startAfterAvanceSong){
                if (!this.songIsPaying) {
                    //this.soundManager.playSong();
                }
            }
        }

        this.avanceSongTimeUpdated.dispatch(this._avanceSongPercent);
        // console.log(startAfterAvanceSong, this.songPosition, Math.abs(value / (Math.PI * turns * 2)), avancePercentTotal);
    }

    get gameProgress() { return this._gameProgress }
    set trackedAngle(value) { 
        if (value == this._trackedAngle) return
        this._trackedAngle = value
        const turnsForWin = AppConfig.totalrotatesForWin; //Progress for win
        let angleForWin =  Math.abs(value / (Math.PI * turnsForWin * 2));
        this._gameProgress = angleForWin / turnsForWin;

        
        if (this._gameProgress >= 1 && this.gameState == EGameStates.playing) {
            this.finishGame();
        }

        this.gameProgressUpdated.dispatch(this._gameProgress);
    }

/*
    set gameProgress(value) { 
        if (value == this._gameProgress) return
        const turnsForWin = AppConfig.totalrotatesForWin; //Progress for win
        let angleForWin =  Math.abs(value / (Math.PI * turnsForWin * 2));
        this._gameProgress = angleForWin / turnsForWin;

        
        if (this._gameProgress >= 1 && this.gameState == EGameStates.playing) {
            this.finishGame();
        }

    
        this.gameProgressUpdated.dispatch(this._gameProgress);
    }
        */

    get songPosition() { return this._songPosition }
    set songPosition(value) { 
        this._songPosition = value;

        if (this.songIsPaying && this._songPosition >= this.avanceSongPercent) {
        }
        this.songPositionUpdated.dispatch(this._songPosition);        
    }
    

    get showRules() { return this._showRules }
    set showRules(value) { 
        if (value == this._showRules) return
        this._showRules = value; 
        this.rulesStatusUpdated.dispatch(value); 
    }

    get gameState() { return this._gameState }
    set gameState(value) { 
        if (value == this._gameState) return
        this._gameState = value; 
        this.gameStateUpdated.dispatch();
    }

    get songIsPaying() {return this.soundManager.songIsPlaying}

    get isAutoPlay() { return this._isAutoPlay}
    set isAutoPlay(value) { 
        this._isAutoPlay = value;

    }
    get isRotating() { return this._isRotating}
    set isRotating(value) { 
        if (value === this._isRotating) return
        clearInterval(this.playAfterStopIntervalID);
        // DebugService.logFPS("isRotating:" + value);
        if (value) {
            if (!this.songIsPaying) {
                
                this.soundManager.playSong();
            }
        } else {
            if (this.songIsPaying) {
                // clearInterval(this.playAfterStopIntervalID);
                if (AppConfig.pauseAfterStopMS) {
                    this.playAfterStopIntervalID = setTimeout(() => {
                        this.soundManager.pauseSong();
                        console.log("jj");
                    }, 
                    AppConfig.pauseAfterStopMS);
                } else {
                    this.soundManager.pauseSong();
                }

            }
        }

        this._isRotating = value;
        this.isRotatingUpdated.dispatch(value);
    }

    init() {
    }

    getParamsByTime(currentTime, configObj) {
        const keys = Object.keys(configObj).map(Number).sort((a, b) => a - b);
        const key = keys.find(interval => interval > currentTime);
        return key ? configObj[key] : configObj['infinity'];
    }

    getParamsByCount(count, configObj) {
        const keys = Object.keys(configObj).map(Number).sort((a, b) => a - b);
        const key = keys.find(interval => interval > count);
        return key ? configObj[key] : configObj['infinity'];
    }

    /**
     *  @param      {Object} probObj
     *              the object with distribution of probabilities in perscetns
     *              {prop1:10, prop2:20, prop3:70}. It must be 100 in summ
     *  @param      {boolean} usePercents=true
     *              determine if the values in objects are defined as percents but
     *  @returns    {string}
     *              propname
     */
    getPropertyByProbability(probObj, usePercents = true) {
        const randomNumber = Math.floor(Math.random() * 100.1);
        const percentFactor = usePercents ? 1 : 100;
        let cumulativeProbability = 0;
        for (const row in probObj) {
            if (probObj.hasOwnProperty(row)) {
                cumulativeProbability += (probObj[row] * percentFactor);
                if (randomNumber <= cumulativeProbability) {
                    return row
                }
            }
        }


        // Default to the last row if the random number exceeds the cumulative probability
        const lastKey = Object.keys(probObj)[Object.keys(probObj).length - 1];
        // return Object.keys(probObj).length - 1;
        return lastKey;
    }



    /***************************
     * Controller
     **************************/
    /**
     * Wrap message to object with {eventName:'eventName'} and sends to the parent
     * @access public
     * @param {string} message
     * @param {Object} data
     */
    sendMessage(message, data){
        this.communictionService.prepareAndSendEvent(message, data);
    }

    /**
     * @access public
     */
    resetGame() {
        const { initialSpeed, initialTimeLeft } = AppConfig.gameSettings;

        this.pauseGame();
        this.scores = 0;
        this.gameRestarted.dispatch();
        this._avanceSongPercent = 0;
        this.avanceAngle = 0;
        this.songPosition = 0;
        this.trackedAngle = 0;
        this._gameProgress = 0;
        this.gameState = EGameStates.ready;
        this.gameStat.resetStats();
        this.isAutoPlay = false;
        this.isRotating = false;

        this.gameTimer.reset();

    }

    /**
     * @access public
     */
    startGame() {
        this.sendMessage(EMessages.SND_GAME_START);
        this.resumeGame();

    }

    /**
     * @access public
     */
    resumeGame() {
        // this.timeSpent = 0;
        this.gameState = EGameStates.playing;
        this.gameStat.resetStats();
        if (this.intervalId) clearInterval(this.intervalId)
        this.intervalId = setInterval(() => {
            // this.updateTime();
        }, 1000);
        this.gameTimer.start();

    }

    /**
     * @access public
     */
    finishGameOld() {
        this.pauseGame();
        this.gameState = EGameStates.stop;
        const sendData = {
            timeSpent: this.timeSpent,
            currentScore: this.scores,
            gameImage: this.gameStat.getItemsHistoryString(),
            items: this.gameStat.getItemsStat()
        };
        const uuid = '' + new UUIDLoader((JSON.stringify(sendData).length).toString(14), sendData, "f0227a92774111eeb9620242ac120002")

        const message = {
            eventName: EMessages.SND_GAME_END,
            score: this.scores,
            gameId:this.communictionService.gameId,
            uuid:uuid,
            gameData: {
                timeSpent: this.timeSpent,
                score: this.scores,
                gameImage: sendData.gameImage,
                items: sendData.items
            }
        };

        console.log(message);
        this.communictionService.sendMessage(message);
    }

    /**
     * @access public
     */
    finishGame() {
        this.pauseGame();
        this.gameState = EGameStates.stop;
        const sendData = {
            timeSpent: this.gameTimer.totalPlayTime,
            currentScore: this.communictionService.gameId,
            // gameImage: this.gameStat.getItemsHistoryString(),
            // items: this.gameStat.getItemsStat()
        };
        const uuid = '' + new UUIDLoader((JSON.stringify(sendData).length).toString(14), sendData, "f0227a92774111eeb9620242ac120002")

        const message = {
            eventName: EMessages.SND_GAME_END,
            // score: this.scores,
            score: this.communictionService.gameId,
            gameId:this.communictionService.gameId,
            uuid:uuid,
            gameData: {
                timeSpent: this.gameTimer.totalPlayTime,
                // score: this.scores,
                // gameImage: sendData.gameImage,
                // items: sendData.items
            }
        };

        console.log(message);
        this.communictionService.sendMessage(message);
    }

    /**
     * @access public
     */
    pauseGame() {
        this.gameState = EGameStates.pause;
        clearInterval(this.intervalId);
        // this.gameStateUpdated.dispatch();
        this.gameTimer.pause();

    }

    /**
     * @access public
     */
    registerCloseInteraction() {
        this.sendMessage(EMessages.SND_GLOSE);
        if (this.gameState === EGameStates.playing){
            this.pauseGame();
        } else if (this.gameState === EGameStates.pause) {
            this.resumeGame();
        };
    }

    /**
     * @access public
     */
    registerAvanceAngle() {
        this.sendMessage(EMessages.SND_GLOSE);
        if (this.gameState === EGameStates.playing){
            this.pauseGame();
        } else if (this.gameState === EGameStates.pause) {
            this.resumeGame();
        };
    }

}
export default GameModel;
