import { Utils } from './Utils'

export function extendPIXI() {
  // console.log('%cPIXI extended', 'border-top: 4px solid #0957EE; border-bottom: 4px solid #ECBF0C;')
  /**
   *  Lerp (Linear interpolation) x and y value to defined one depends on t value
   * @param x
   * @param y
   * @param t
   * @returns {PIXI.ObservablePoint}
   */
  PIXI.ObservablePoint.prototype.lerp = PIXI.Point.prototype.lerp = function (x, y, t) {
    if (x.hasOwnProperty('x') && x.hasOwnProperty('y')) {
      this.x = Utils.lerp(y, this.x, x.x)
      this.y = Utils.lerp(y, this.y, x.y)
      return this
    }
    this.x = Utils.lerp(t, this.x, x)
    this.y = Utils.lerp(t, this.y, y)
    return this
  }

  PIXI.ObservablePoint.prototype.distance = PIXI.Point.prototype.distance = function (point) {
    if (point && point.hasOwnProperty('x') && point.hasOwnProperty('y')) {
      return Utils.distanceXY(this.x, this.y, point.x, point.y)
    }
    else {
      return Utils.distanceXY(this.x, this.y)
    }
  }

  PIXI.ObservablePoint.prototype.angleTo = PIXI.Point.prototype.angleTo = function (point) {
    if (point && point.hasOwnProperty('x') && point.hasOwnProperty('y')) {
      return Utils.angleFromXY(this.x, this.y, point.x, point.y)
    }
    else {
      return Utils.angleFromXY(0, 0, this.x, this.y)
    }
  }

  PIXI.ObservablePoint.prototype.add = PIXI.Point.prototype.add = function (x, y) {
    if (x.hasOwnProperty('x') && x.hasOwnProperty('y')) {
      this.x = this.x + x.x
      this.y = this.y + x.y
      return this
    }
    this.x = this.x + x
    this.y = this.y + y
    return this
  }

  PIXI.ObservablePoint.prototype.subtract = PIXI.Point.prototype.subtract = function (x, y) {
    if (x.hasOwnProperty('x') && x.hasOwnProperty('y')) {
      this.x = this.x - x.x
      this.y = this.y - x.y
      return this
    }
    this.x = this.x - x
    this.y = this.y - y
    return this
  }

  PIXI.ObservablePoint.prototype.multiply = PIXI.Point.prototype.multiply = function (x, y) {
    if (x.hasOwnProperty('x') && x.hasOwnProperty('y')) {
      this.x = this.x * x.x
      this.y = this.y * x.y
      return this
    }
    this.x = this.x * x
    this.y = this.y * y
    return this
  }

  PIXI.ObservablePoint.prototype.divide = PIXI.Point.prototype.divide = function (x, y) {
    if (x.hasOwnProperty('x') && x.hasOwnProperty('y')) {
      this.x = this.x / x.x
      this.y = this.y / x.y
      return this
    }
    this.x = this.x / x
    this.y = this.y / y
    return this
  }

  PIXI.ObservablePoint.prototype.multiplyScalar = PIXI.Point.prototype.multiplyScalar = function (a) {
    this.x = this.x * a
    this.y = this.y * a
    return this
  }

  PIXI.ObservablePoint.prototype.normalize = PIXI.Point.prototype.normalize = function () {
    const a =   Math.sqrt(this.x*this.x + this.y*this.y)
    this.x = this.x / a
    this.y = this.y / a
    return this
  }
}
