import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';
import { AppConfig } from '../../config/AppConfig';
import GameModel from '../../model/GameModel';
import EMessages from '../../services/EMessages';
import ResourceList from '../../services/ResourceList';
import SoundData from '../../services/SoundData';
import GameScreen from '../screens/GameScreen';
import SpriteCommon from './common/SpriteCommon';
import StartButton from './StartButton';

class Countdown extends PIXI.Container {
    /**
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameModel, gameScreen) {
        super();
        const { gameHeight } = AppConfig.settings;
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;
        this.soundManager = gameScreen.soundManager;
        this.bg = new PIXI.Graphics();
        this.bg.eventMode = "passive";
        this.showTrainer = true;
        this.showTrainer =  this.gameModel.communictionService.shouldShowTutorial;
        this.gameModel.communictionService.tutorialStatusUpdated.add((shouldShow) => {
            this.showTrainer = shouldShow;
        });

        this.pointerGuide = new TreinerPointer();
        this.pointerGuide.visible = false;
        this.pointerGuide.eventMode = "passive";
        this.trainerIntervalID = -1;

        this.trainPassed = {left: false, center: false, righ:false};
        this.trainCurrentPos = -1;

        this.setTrainerByPos = (pos) =>{
            const { gameWidth, gameHeight } = AppConfig.settings;
            this.pointerGuide.iconPos = pos;
            const f = (1/3) * pos;
            const conveyorWidth2D = gameWidth;
            const trgX =  gameWidth * f;
            const trgY =  gameHeight / 2 - 60; 
            this.pointerGuide.phase = 0;
    
            gsap.timeline()
            .to(this.pointerGuide, { 
                x: trgX, 
                y: trgY, duration: 0.6, 
                ease: "power.out", 
                delay:1,
                onComplete: () => {
                    this.checkClicked(this.trainCurrentPos);
                }})
            .to(this.pointerGuide, { phase: Math.PI * 8, duration: 2, ease: "none" })
    
        }

        this.onResize = () => {
            const { gameHeight } = AppConfig.settings;
            this.redrawBG();
            this.reglesButton.y = gameHeight / 2 - 10;
            this.startButton.y = gameHeight / 2 - 50;
        }

        this.onSongLoaded = () => {
            gsap.to(this.startButton, { alpha: 1, duration: 1.9});
  
        }

        this.soundManager.onSongLoadedSignal.add(this.onSongLoaded);

        AppConfig.sizeUpdated.add(this.onResize);
        
        this.addChild(this.bg);
        
        this.text3 = new SpriteCommon(ResourceList.COUNTDOWN_3);
        this.text2 = new SpriteCommon(ResourceList.COUNTDOWN_2);
        this.text1 = new SpriteCommon(ResourceList.COUNTDOWN_1);
        this.text0 = new SpriteCommon(ResourceList.COUNTDOWN_GO);

        this.startButton = new StartButton();
        this.addChild(this.startButton);
        this.startButton.alpha = 1;
        
        this.reglesButton = new SpriteCommon(ResourceList.MSC_BTN_REGLES);

        // this.addChild(this.reglesButton);
        this.reglesButton.anchor.set(0.5, 0.5);
        this.reglesButton.eventMode = "dynamic";

        this.title = new SpriteCommon(ResourceList.HELLO_TITLE);
        this.title.eventMode = "none";
        this.title.scale.set(1, 1);
        this.title.anchor.set(0.5, 1);
        this.title.y = 160;
        
        // this.addChild(this.title);

        this.reglesButton.on('pointerdown', ()=> {
            this.gameModel.showRules = true;
        });
        
        this.startButton.on('startButtonClick', () => {
            this.emit('countdownStarted');
            this.text3.alpha = 0;
            const duration = 1;
            gsap.timeline()
            .to(this.startButton, { alpha: 0, duration: duration, onStart: () => this.text3.visible = true })
            .call(() => {
                if (this.showTrainer) {
                    this.startTrainer();
                } else {
                    this.startCountdown();
                }
                this.startButton.visible = false;
                this.title.visible = false;               
            }); 

            gsap.to(this.title, { alpha: 0, duration: duration});

          });

        this.addChild(this.pointerGuide);



        this.onResize();


    }

    startTrainer() {

        this.pointerGuide.visible = true;
        this.bg.eventMode = "passive";
        this.pointerGuide.alpha = 0;

        gsap.to(this.title, { alpha: 1, duration: 0.6, onComplete:()=> {
            this.setTrainerByPos(-1);
        }});
        gsap.to(this.reglesButton, { alpha: 0, duration: 0.6, delay: 0, 
            onComplete:()=>{
            this.reglesButton.visible = false;
        }});
    }

    checkClicked(pos) {
        if (this.showTrainer == false) return
        if (this.gameModel.cartLine === pos) {
            if (pos === 1) {
                this.pointerGuide.visible = false;
                this.startCountdown();
                this.sendTutorialPassed();
            } else {
                this.trainCurrentPos ++;
                this.setTrainerByPos(this.trainCurrentPos);
            }
        }

        if (this.trainerIntervalID > 0) clearTimeout(this.trainerIntervalID);
        // this.trainerIntervalID = setTimeout(this.setTrainerByPos, 5000, this.trainCurrentPos);
    }

    sendTutorialPassed() {
        this.gameModel.sendMessage(EMessages.SND_TUTORIAL_END);
    }
    /**
     * @access public
     */
    resetCountDown() {
        this.visible = true;
        this.startButton.visible = true;
        this.startButton.alpha = 1;
        this.title.alpha = 1;
        this.visible = true;
        this.title.visible = true;
        this.reglesButton.visible = true;
        this.reglesButton.alpha = 1;
    }

    startCountdown() {
        const { counDownStepDuration } = AppConfig.gameSettings;
        const duration = counDownStepDuration;
        this.trainCurrentPos = -1;
        this.showTrainer = false;
        this.pointerGuide.visible = false;
        clearInterval(this.trainerIntervalID);
        gsap.to(this.reglesButton, { alpha: 0, duration: duration, delay: 0, 
            onComplete:()=>{
            this.reglesButton.visible = false;
            // this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
        }});
        gsap.timeline()
            .to(this.text3, { alpha: 1, duration: duration, delay: duration })
          .to(this.text3, { alpha: 0, duration: duration, delay: duration })
          .to(this.text2, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text2.visible = true;
                // this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text2, { alpha: 0, duration: duration, delay: duration })
          .to(this.text1, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text1.visible = true;
                // this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN);
            }})
          .to(this.text1, { alpha: 0, duration: duration, delay: duration })
          .to(this.text0, { alpha: 1, duration: duration, 
            onStart: () => {
                this.text0.visible = true;
                // this.gameScreen.soundManager.play(SoundData.MSC_COUNDDOWN_GO);
            }})
          .to(this.text0, { alpha: 0, duration: duration, delay: duration })
          .call(() => {
            // this.emit('countdownComplete');
            this.text3.visible = false;
            this.text2.visible = false;
            this.text1.visible = false;
            this.text0.visible = false;
            this.visible = false;
            // this.gameScreen.soundManager.play(SoundData.MSC_START);
        }); 


        this.emit('countdownComplete');
        this.visible = false;
        // this.gameScreen.soundManager.play(SoundData.MSC_START);
    }

    redrawBG(){
        const { gameWidth, gameHeight } = AppConfig.settings;
        this.bg.clear();
        this.bg.beginFill(0x222222);
        this.bg.drawRect( - gameWidth / 2, - gameHeight / 2,  gameWidth, gameHeight);
        this.bg.endFill();
        this.bg.alpha = 0.0;
    }

    
}

class TreinerPointer extends PIXI.Sprite {
    constructor() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        super(PIXI.Texture.EMPTY);
        this.mouseIconLeft = new SpriteCommon(ResourceList.MSC_CART_CLICKER_LEFT);
        this.mouseIconCenter = new SpriteCommon(ResourceList.MSC_CART_CLICKER_CENTER);
        this.mouseIconRight = new SpriteCommon(ResourceList.MSC_CART_CLICKER_RIGHT);
        this.addChild(this.mouseIconLeft);
        this.addChild(this.mouseIconCenter);
        this.addChild(this.mouseIconRight);
        // this.mouseIconLeft.alpha = 1;
        // this.mouseIconCenter.alpha = 0;
        // this.mouseIconRight.alpha = 0; 

        this.mouseIconLeft.eventMode = "passive";
        this.mouseIconCenter.eventMode = "passive";
        this.mouseIconRight.eventMode = "passive";

        this.mouseIconLeft.anchor.set(0.5, 0.5);
        this.mouseIconCenter.anchor.set(0.5, 0.5);
        this.mouseIconRight.anchor.set(0.5, 0.5);

        this._iconPos = 0;
        this.iconPos = -1;

        this.eventMode = "passive";
        this._phase = Math.PI / 2;
        this.anchor.set(0.5, 0.5);
        this.y = 100;       
    }

    get phase() {return this._phase};
    set phase (value) {
        if (this._phase === value) return
        this._phase = value;
        this.alpha = Math.abs(Math.cos(value))
    }

    get iconPos() {return this._iconPos};
    set iconPos (value) {
        if (this._iconPos === value) return
        this._iconPos = value;
        const duration = 0.7;
        const delay = 0.75;
        if (value === -1) {
            
            this.mouseIconLeft.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 1, duration: duration, delay: delay });
            gsap.to(this.mouseIconCenter, { alpha: 0, duration: duration, delay: delay });
            gsap.to(this.mouseIconRight, { alpha: 0, duration: duration, delay: delay });
        }
        if (value === 0) {
            this.mouseIconCenter.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconCenter, { alpha: 1, duration: duration, delay: delay});
            gsap.to(this.mouseIconRight, { alpha: 0, duration: duration, delay: delay});
        }
        if (value === 1) {
            this.mouseIconRight.alpha = 0;
            gsap.to(this.mouseIconLeft, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconCenter, { alpha: 0, duration: duration, delay: delay});
            gsap.to(this.mouseIconRight, { alpha: 1, duration: duration, delay: delay});
        }
    }  


}




export default Countdown