import ResourceList from "../../../services/ResourceList";
import InfoBoxWithIcon from "./InfoBoxWithIcon";

class ScoreInfo extends InfoBoxWithIcon {
    constructor() {
        super(ResourceList.MSC_COIN);
        this.addChild(this.labelMaxShadow);
        this.addChild(this.labelMax);
        this.labelMax.anchor.set(0, -1);
        this.labelMaxShadow.anchor.set(0, -1); 
    }

    /**
     * @access public
     */
    resizeLabel() {
        super.resizeLabel();
        this.labelMax.x = this.label.x + this.label.width;
        this.labelMax.y = this.label.y; 
        this.labelMaxShadow.x = this.labelMax.x + this.shadowX; 
        this.labelMaxShadow.y = this.labelMax.y + this.shadowY; 
        const bgWidth = this.bg.width + this.labelMax.width;
        this.drawBg(bgWidth);
    }


    /**
     * @access public
     * @param {number} seconds 
     */
    setScores(value){
        this.label.text = value +  "/";
        this.labelShadow.text = value + "/";
        this.resizeLabel();
    }

}

export default ScoreInfo