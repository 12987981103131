import * as PIXI from 'pixi.js';
import GameScreen from "./view/screens/GameScreen";
import ResourceService from "./services/ResourceService";
import GameModel, { EGameStates } from './model/GameModel';
import { AppConfig } from './config/AppConfig';
import Fireworks from './view/components/effects/Fireworks';
import EMessages from './services/EMessages';
import gsap from 'gsap';
import GAME_CONFIG from './config/GameConfig';
import DebugService from './services/DebugService';

class Game extends PIXI.Container {
    constructor(app) {
        super();
        this.app = app;
        this.gameModel = new GameModel();
        this.lastTime = 0;

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                // this.app.ticker.start();
            }
            if (this.gameModel.gameState === EGameStates.stop) {
                // this.app.ticker.stop();
            };
        };

        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);


        ResourceService.init(() => {
            this.gameModel.sendMessage(EMessages.SND_ALL_LOADED);
            const preloader = document.getElementById('preloader');
            this.gameScreen = new GameScreen(this.app, this.gameModel);
            // this.gameScreen.soundManager.preloadAllSounds();
                            // AppConfig.updateParamsFromSound(songDuration);
                            AppConfig.updateParamsFromSound(0.31);
                            gsap.to(preloader, {
                                y: '-100%',
                                duration: 1,
                                ease: 'power3.inOut',
                                onComplete: () => {
                                  preloader.remove();
                              },
                              });
                  
                              this.clearPreloader();
                              this.startApp();
                              this.animate();
            this.gameScreen.soundManager.onSongLoadedSignal.add(() => {
                //const songDuration = this.gameScreen.soundManager.songSound.duration();
                // DebugService.logIncr("Song is Loaded");
            });
        });

        this.animate = (time) => {
            // if (this.gameModel.gameState !== EGameStates.playing) return
            const delta = time - this.lastTime;
            requestAnimationFrame(this.animate);
            this.app.renderer.render(this.app.stage);
            this.gameScreen?.animate(delta);
            this.lastTime = time;

        };
    }

    startApp() {
        this.cleanUp();
        this.addChild(this.gameScreen);
    };

  

    cleanUp() {
        this.removeChildren();
    };

    clearPreloader() {
        if (window.preloaderInterval) {
            clearInterval(window.preloaderInterval);
        }
    }


}

export default Game;
