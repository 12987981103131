class EEffectNames {};
EEffectNames.STARS = "stars";
EEffectNames.TIME = "time";
EEffectNames.DROPS = "drops";
EEffectNames.SHIMMER = "shimmer";
EEffectNames.SHIMER_STARS = "shimmerStars";
EEffectNames.BAD_CIRCLES = "badCircles";
EEffectNames.POWER_UPS = "powerUps";

EEffectNames.NOTES_1 = "notes1";
EEffectNames.NOTES_2 = "notes2";
EEffectNames.NOTES_3 = "notes3";
EEffectNames.NOTES_4 = "notes4";

EEffectNames.SCORES_1 = "scores1";


EEffectNames.SPEED_FX = "speedFX";


EEffectNames.list = [
    // EEffectNames.STARS,
    // EEffectNames.TIME,
    // EEffectNames.DROPS,
    // EEffectNames.SHIMMER,
    // EEffectNames.SHIMER_STARS,
    // EEffectNames.BAD_CIRCLES,
    // EEffectNames.POWER_UPS,
    EEffectNames.NOTES_1,
    EEffectNames.NOTES_2,
    EEffectNames.NOTES_3,
    EEffectNames.NOTES_4

]

export default EEffectNames