import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';

class InfoBox extends PIXI.Container {
    constructor() {
        super();
        this.gap = 10;
        this.margin = 15;
        this.shadowX = 4;
        this.shadowY = 2;
        this.cornerRadius = 16;

        this.bg  = new PIXI.Graphics();
        this.drawBg(88)
        this.addChild(this.bg);

        this.labelShadow = new Text('0', {
            fontFamily: 'MainMusicPlayer',
            fontSize: 28,
            fill: 0x8844ff,
            lineHeight:50,
            align: 'left'
        });

        this.label = new Text('0', {
            fontFamily: 'MainMusicPlayer',
            fontSize: 28,
            fill: 0xffffff,
            lineHeight:50,
            align: 'left'
        });

        //we add it here because Text instancse cannot be created in the construcor of a Sprite heir
        this.labelMaxShadow = new Text('1000', {
            fontFamily: 'MainMusicPlayer',
            fontSize: 18,
            fill: 0x9C42FF,
            lineHeight:18,
            align: 'left'
        });

        this.labelMax = new Text('1000', {
            fontFamily: 'MainMusicPlayer',
            fontSize: 18,
            fill: 0xffffff,
            lineHeight:18,
            align: 'left'
        });

        this.addChild(this.labelShadow);
        this.addChild(this.label);

        this.label.x = this.bg.width;
        this.label.y = 0;

        this.labelShadow.x = this.label.x + this.shadowX;
        this.labelShadow.y = this.label.y + this.shadowY;   
    }

    /**
     * @access public
     * @param {number} value sets Scale with keeping 9 cells corner not transformed
     */
    setComponentWidth(value) {
        // this.bg.width = value;
        this.drawBg(value)
    }

    drawBg(w) {
        this.bg.clear();
        this.bg.beginFill(0x000000);
        this.bg.drawRoundedRect( 0, 0, w, 44, 8 );
    }
}

export default InfoBox