import * as PIXI from 'pixi.js';
import ResourceList from '../../../services/ResourceList';
import Particle from './Particle';
class Fireworks extends PIXI.Container  {
    constructor(gameScreen) {
        super();
        this.textureList = [
            ResourceList.NOTE_1,
            ResourceList.NOTE_2,
            ResourceList.NOTE_3,
            ResourceList.NOTE_4
        ]
        this.emitters = new Array();
        this.emitterX = 0;
        this.emitterY = 0;
        this.emitterDisplaceX = 0;
        this.emitterDisplaceY = 0;
        this.emitterAngle = 0;
        this.frameCounter = 0;
        this.addOnEachFrame = 0.25;
        this.allowNewParticles = true;
        this.t = 0;
        this.angleTarget = Math.PI * 3 / 2;
        this.angleIncr = 0;
        this.angleAcc = 0;


        // this.addParticle();
        // this.addParticle();
    }

    /**
     * @access public - update on each frame
     */
    update() {
        this.frameCounter ++;

        this.angleAcc = Particle.randRange(-0.005, 0.005);
        this.angleIncr += this.angleAcc;
        this.angleTarget += this.angleIncr;



        if (this.allowNewParticles) {
            if (this.addOnEachFrame >= 1){
                if (this.frameCounter % this.addOnEachFrame === 0) {
                    this.addParticle();
                }
            } else {
                const n = 1 / this.addOnEachFrame;
                for (let i = 0; i < n; i++) {
                    this.addParticle();               
                }
            }
        }
        for (const p of this.children) {
            if (p instanceof Particle){
                p.update();
                if (p.t >= p.lifeTime) this.removeChild(p);
            }
        }
    }

    /**
     * @access public
     * @param {Sprite} emitter a Sprite with emitterDisplaceX and emitterDisplaceY protperties
     * @param {number} displaceX 
     * @param {number} displaceY 
     */
    addEmmiter(emitter, displaceX, displaceY) {
        
        emitter.emitterDisplaceX = displaceX;
        emitter.emitterDisplaceY = displaceY;
        if (this.emitters.indexOf(emitter) >  -1) return
        emitter.on('stopEmitting', () => {
            this.removeEmmiter(emitter);
        });
        this.emitters.push(emitter);
    }

    /**
     * @access public
     * @param {Sprite} emitter 
     */
    removeEmmiter(emitter) {
        this.emitters = this.emitters.filter(item => item !== emitter);
    }

    /**
     * @access public
     */
    removeAllEmmiters(emitter) {
        this.emitters.forEach(element => {
            this.removeEmmiter(element);
        });
    }

    addParticle() {
            // const angle = Particle.randRange(- Math.PI, Math.PI);
            const angle = Particle.randRange(- Math.PI, Math.PI) + Math.PI;
            // const angle = this.angleTarget;
            const colorRange = 0xffffff - 0xeeeeee;
            const colorIncr = Math.floor(Math.abs(Math.sin(this.frameCounter / 1000) * colorRange) * 0.05);
            const colorTint = 0xffffff - colorIncr;
            const rSpeed = Particle.randRange(- Math.PI / 60, Math.PI / 60);
            // const rSpeed = 0;
            const speedAbs = Particle.randRange( 2.1, 6.8);
            const posX = this.emitterX + this.emitterDisplaceX;
            const posY = this.emitterY + this.emitterDisplaceY;
            // const p = new Particle(ResourceList.NOTE_1,  posX,  posY, this.angle, speedAbs, rSpeed, 300);
            const p = new Particle(this, this.getRandomItem(this.textureList),
                posX,  posY, angle, speedAbs, rSpeed, 100);
            // p.tint = colorTint;
            // const sc = Particle.randRange( 0.1, 1.6);
            // const sc = 0.1 + Math.pow(0.25, Math.random());
            const sc = 0.1 + Math.random() * Math.random() * Math.random()  * Math.random();
            p.scale.set(sc * 2);
            p.alpha = 0.5;
            this.addChild(p);

    }


    addParticleToEmitters() {
        this.emitters.forEach((emitter) => {
            if (emitter && emitter?.position){
                // const angle = Particle.randRange(- Math.PI, Math.PI);
                // const angle = Particle.randRange(- Math.PI / 8, Math.PI / 8) + Math.PI;
                const angle = 0;
                const rSpeed = Particle.randRange(- Math.PI / 60, Math.PI / 60);
                // const rSpeed = 0;
                const speedAbs = Particle.randRange( 0.1, 1.8);
                const posX = emitter.x + emitter.emitterDisplaceX;
                const posY = emitter.y + emitter.emitterDisplaceY;
                const p = new Particle(this, ResourceList.NOTE_1, posX, posY,
                    angle, speedAbs, rSpeed, 300);
                // const sc = Particle.randRange( 0.1, 1.6);
                // const sc = 0.1 + Math.pow(0.25, Math.random());
                const sc = 0.1 + Math.random() * Math.random() * Math.random()  * Math.random();
                p.scale.set(sc * 2);
                this.addChild(p);
            } else {
                this.removeEmmiter(emitter);
            }

        });  

    }

    /*
    addParticle() {
        this.emitters.forEach((emitter) => {
            const angle = Particle.randRange(- Math.PI, Math.PI);
            const rSpeed = Particle.randRange(- Math.PI / 60, Math.PI / 60);
            const speedAbs = Particle.randRange( 2, 8);
            const p = new Particle(this, ResourceList.NOTE_1, this.emitterX, this.emitterY.y,
                angle, speedAbs, rSpeed, 30);
            const sc = Particle.randRange( 0.7, 1.6);
            p.scale.set(sc);
            this.addChild(p);
        });  

    }
        */

    setEmmiterPos(posX, posY) {
        this.emitterX = posX;
        this.emitterY = posY;
    }

    setEmmiterAngle(angle, radius) {
        this.emitterDisplaceX = angle * Math.cos(angle) + radius;
        this.emitterDisplaceY = angle * Math.cos(angle) + radius;
        this.angle = angle;
    }

    getRandomItem(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

}

export default Fireworks