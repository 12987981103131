import CryptoJS from "crypto-js";
class UUIDLoader {

    constructor(event, gameData, uuid_raw) {
        const event0_rqwg1fhc = "event0_rqwg1fhc";
        const event0_hdwe5k0iix = "event0_hdwe5k0iix";
        const event0_4f7tnxdkzsn = "event0_4f7utnxdkzsn";
        const event0_zds4h5fjc8 = "event0_rzds4h5fjc8";
        const event0_qf8rvo2ft = "event0_qfS8rvo2ft";
        const event0_xsy5brgx = "event0_xsry5brgx";
        const event0_46i476lcon = "event0_46i476lcon";
        const event0_lro5obnj4n = "event0_lro5obnj4n";
        const event0_h92vesfrr = "event0_h92vesfrr";
        const event0_f1gayobvu = "event0_f1gayoerbvu";
        const event0_ei2np3qtiw = "event0_ei2np3qtiw";
        const event0_wcmj30cvs = "event0_wcmj30cvs";
        const event0_b5vpaiwj1i = "event0_b5vpaiwoj1i";
        const event0_ll769jctq = "event0_ll769jctq";
        const event0_0p3pmf7vb4 = "event0_0p3pmf7vb4";
        const event0_mgwlpy7k99 = "event0_mgwlpy7k99";
        const event0_50ozyg5psd = "event0_50ozyg5psdt";
        const event0_cepb9t7wps = "event0_cepb9t7wps";
        const event0_qi73p5ta = "event0_qei73p5ta";

        switch (event.substring(4, 14)) {
            case event0_rqwg1fhc:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[3] +
                    event0_f1gayobvu[4] +
                    event0_h92vesfrr[12] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[27] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[700] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[1] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_4f7tnxdkzsn:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[39] +
                    event0_f1gayobvu[134] +
                    event0_h92vesfrr[1312] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[173] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[314] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[21]], uuid_raw).toString();
                break;
            case event0_xsy5brgx:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[143] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[104] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[95] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[114] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[25]], uuid_raw).toString();
                break;
            case event0_f1gayobvu:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[144] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[150] +
                    event0_50ozyg5psd[1887] +
                    event0_qf8rvo2ft[89] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[87] +
                    event0_qi73p5ta[20]], uuid_raw).toString();
                break;
            case event0_h92vesfrr:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[91] +
                    event0_f1gayobvu[322] +
                    event0_h92vesfrr[1] +
                    event0_ei2np3qtiw[0] +
                    event0_50ozyg5psd[1] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[177] +
                    event0_zds4h5fjc8[77] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_ei2np3qtiw:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_xsy5brgx[9] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_50ozyg5psd:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_qf8rvo2ft:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_cepb9t7wps:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_b5vpaiwj1i:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_zds4h5fjc8:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_qi73p5ta:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_ll769jctq:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_wcmj30cvs:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_0p3pmf7vb4:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[101] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
            case event0_lro5obnj4n:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[104] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[184] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[92]], uuid_raw).toString();
                break;
            case event0_hdwe5k0iix:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[104] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[100] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[37] +
                    event0_qi73p5ta[882]], uuid_raw).toString();
                break;
            case event0_mgwlpy7k99:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[106] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[6] +
                    event0_h92vesfrr[110] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[6] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[32]], uuid_raw).toString();
                break;
            case event0_46i476lcon:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[310] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[20] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[0] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[17] +
                    event0_b5vpaiwj1i[0] +
                    event0_zds4h5fjc8[0] +
                    event0_qi73p5ta[0]], uuid_raw).toString();
                break;
            default:
                this['rev'] = CryptoJS.AES
                    .encrypt('' + gameData[
                    event0_rqwg1fhc[14] +
                    event0_4f7tnxdkzsn[10] +
                    event0_xsy5brgx[9] +
                    event0_f1gayobvu[14] +
                    event0_h92vesfrr[11] +
                    event0_ei2np3qtiw[10] +
                    event0_50ozyg5psd[17] +
                    event0_qf8rvo2ft[9] +
                    event0_cepb9t7wps[7] +
                    event0_b5vpaiwj1i[14] +
                    event0_zds4h5fjc8[7] +
                    event0_qi73p5ta[2]], uuid_raw).toString();
                break;
        }
    }
    toString() {
        return '' + this['rev'];
    }
}

export default UUIDLoader;