import * as PIXI from 'pixi.js';
import GameScreen from '../screens/GameScreen';
import GameModel, { EGameStates } from '../../model/GameModel';
import SpriteCommon from './common/SpriteCommon';
import ResourceList from '../../services/ResourceList';
import { AppConfig } from '../../config/AppConfig';
import gsap from 'gsap';

class Wave extends PIXI.Container {
    /**
     * @param {GameModel} gameModel 
     * @param {GameScreen} gameScreen 
     */
    constructor(gameModel, gameScreen) {
        super();
        this.gameModel = gameModel;
        this.gameScreen = gameScreen;
        this._progress = 0;
        this._avanceProgress = 0;

        this.waveBack = new SpriteCommon(ResourceList.WAVE);
        this.waveBack.anchor.set(0.5, 0);
        

        this.waveBackMask = new PIXI.Graphics();
        this.waveBackMask.beginFill(0x660000);
        this.waveBackMask.drawRect(0, 0, 200, 150);
        this.waveBackMask.endFill();
       
        if (!AppConfig.showFullAvanceProgress && !AppConfig.hideBack) {
            this.addChild(this.waveBack);
            this.addChild(this.waveBackMask);
            this.waveBack.mask = this.waveBackMask;
            this.waveBack.alpha = 0.3;
        } else {
            this.waveBack.alpha = 0;
        }



        this.waveFront = new SpriteCommon(ResourceList.WAVE);
        this.waveFront.anchor.set(0.5, 0);
        this.addChild(this.waveFront);

        this.waveMask = new PIXI.Graphics();
        this.waveMask.beginFill(0x660000);
        this.waveMask.drawRect(0, 0, 200, 150);
        this.waveMask.endFill();
        this.waveMask.alpha = 0.8;
        this.addChild(this.waveMask);

        this.waveFront.mask = this.waveMask;

        this.onResize = () => {
            const { gameWidth, gameHeight, vPosWave } = AppConfig.settings;
            const w = gameWidth;
            const h = gameHeight;
            this.w = w;
            this.h = h;
            this.waveBack.x = w / 2;
            this.waveFront.x = w / 2;
            this.waveMask.width = this.waveFront.width * this.progress;
            this.waveMask.x = w / 2 - this.waveFront.width / 2;
            this.waveBackMask.x = this.waveMask.x;

            const top = h * vPosWave;
            this.waveBack.y = top;
            if (AppConfig.showFullAvanceProgress) {
                this.waveFront.y = top;
                this.waveMask.y = top;
            } else {
                this.waveFront.y = top;
                this.waveMask.y = top;
            }

            this.waveBackMask.y = top;

            // this.progress = this.gameModel.avanceSongPercent;
            if (AppConfig.fastWaves) {
                this.progress = this.gameModel.gameProgress;
            } else {
                this.progress = this.gameModel.avanceSongPercent;
            }
            
            // this.progress = 0.5;
            this.avanceProgress = this.gameModel.avanceSongPercent;

        }

        this.onGameStateUpdated = () => {
            // if (!AppConfig.showFullAvanceProgress) return
            if (this.gameModel.gameState === EGameStates.playing){
                this.waveFront.alpha = 0;
                gsap.to(this.waveFront, { alpha: 1, duration: 1.9});
                this.progress = this.gameModel.gameProgress;
            } else {
                gsap.to(this.waveFront, { alpha: 0, duration: 1.9});
            }

        };

        this.onAvanceSongTimeUpdated = (value) => {
            if (AppConfig.showFullAvanceProgress) {
                this.avanceProgress = 1;
            } else {
                this.avanceProgress = value;
            }
        };
        this.onGameProgressUpdated = (value) => {
            if (AppConfig.fastWaves) this.progress = this.gameModel.gameProgress;
        };

        this.onSongPlayingStatusChanged = (isPlaying) => {


        }

        this.onSongPositionUpdated = (value) => {
            if (!AppConfig.fastWaves) this.progress = value;
        };

        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);
        this.gameModel.avanceSongTimeUpdated.add(this.onAvanceSongTimeUpdated);
        this.gameModel.songPositionUpdated.add(this.onSongPositionUpdated);
        this.gameModel.gameProgressUpdated.add(this.onGameProgressUpdated);
        this.gameScreen.soundManager.playingStatusUpdated.add(this.onSongPlayingStatusChanged);

        AppConfig.sizeUpdated.add(this.onResize.bind(this));
        
        this.onResize();


    }

    get progress() {return this._progress;}

    set progress(value) {
        this._progress = value;
        this.waveMask.width = this.waveFront.width * this._progress;
    }
    
    get avanceProgress() {return this._avanceProgress;}

    set avanceProgress(value) {
        this._progress = value;
        this.waveBackMask.width = this.waveFront.width * this._progress;
    }
    


}

export default Wave