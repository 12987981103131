import * as PIXI from 'pixi.js';
import SpriteCommon from './common/SpriteCommon';
import ResourceList from '../../services/ResourceList';

class StartButton extends PIXI.Sprite {
  constructor() {
    super();


    this.cursor = "pointer";
    this.interactive = true;
    this.eventMode = "dynamic";

    // this.buttonLabel = new PIXI.Text('Start', { fontSize: 40  , fill: 0xFFFFFF });
    this.buttonImage = new SpriteCommon(ResourceList.START_BUTTON);
    this.addChild(this.buttonImage);
    this.cursor = "pointer";

    this.buttonImage.position.set( - this.buttonImage.width / 2,  - this.buttonImage.height / 2);

    this.buttonMode = true;

    this.on('pointerdown', this.onButtonDown.bind(this));
    this.on('pointerup', this.onButtonUp.bind(this));
    this.on('pointerout', this.onButtonOver.bind(this));
  }


  onButtonDown() {
    if (this.isMobile) {
      //this.emit('startButtonClick');
    } 
    // this.emit('startButtonClick');
    // this.buttonLabel.texture = this.downTexture;
    // this.buttonLabel.y = 3;
    // this.buttonImage.y = -2;
    this.emit('startButtonClick');

}

  onButtonUp() {
    if (!this.isMobile) {
      //this.emit('startButtonClick');
    } 
    
    // this.buttonImage.y = 0;
  }

  onButtonOver() {
    // this.buttonImage.y = 0;
  }
  onButtonOut() {
    // this.buttonImage.y = 0;
  }
}

export default StartButton
